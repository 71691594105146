export const mulberry32 = (seed: number) => {
  return () => {
    let t = (seed += 1831565813);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
};

registerProcessor(
  'StochasticRhythmProcessor',
  class StochasticRhythmProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'clock',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'reset',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'randomize',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
      ];
    }

    lastClock = 0;
    lastReset = 0;
    lastRandomize = 0;
    position = -1;
    seed: number;
    prng: () => number;
    weights: number[];
    sequence: boolean[];

    constructor(options: AudioWorkletNodeOptions) {
      super(options);

      this.seed = options.processorOptions.seed;
      this.prng = mulberry32(this.seed);

      this.weights = options.processorOptions.weights;
      this.sequence = this.weights.map(v => v > this.prng());

      this.port.onmessage = ({ data: [type, value] }) => {
        switch (type) {
          case 'weights':
            this.weights = value;
            this.sequence = this.weights.map(v => v > this.prng());
            this.position =
              value.length === 0 ? 0 : this.position % value.length;
            break;
          case 'seed':
            this.seed = value;
            this.prng = mulberry32(value);
            this.sequence = this.weights.map(v => v > this.prng());
            break;
          default:
            throw new Error('unrecognized message');
        }
      };
    }

    process(
      _: Float32Array[][],
      [[output]]: Float32Array[][],
      {
        clock: [clock],
        reset: [reset],
        randomize: [randomize],
      }: { [param: string]: Float32Array },
    ): boolean {
      if (this.sequence.length > 0) {
        if (this.lastRandomize <= 0 && randomize > 0) {
          this.sequence = this.weights.map(v => v > this.prng());
          this.port.postMessage(['sequence', this.sequence]);
        }

        if (this.lastReset <= 0 && reset > 0) {
          this.position = 0;
          this.port.postMessage(['position', 0]);
          if (this.sequence[this.position]) {
            output[0] = 1;
          }
        } else if (this.lastClock <= 0 && clock > 0) {
          this.position = (this.position + 1) % this.sequence.length;
          this.port.postMessage(['position', this.position]);
          if (this.sequence[this.position]) {
            output[0] = 1;
          }
        }

        this.lastRandomize = randomize;
        this.lastReset = reset;
        this.lastClock = clock;
      }
      return true;
    }
  },
);
